<template>
<div>
    <b-modal id="modal-edit" ref="my-modal-edit" title="Form Edit Category Principle" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk" @hidden="$parent.handleCancel">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label class="text text-info">Principle</label>
                    <v-select label="name" :filterable="false" :options="principles" v-model="formInput.principle_name" :reduce="principles => principles.id" @search="onSearchPrinciple" @input="setSelectedPrinciple">
                        <template slot="no-options"> type to search principle... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.logo' style="width: 1rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">Category</label>
                    <v-select label="name" :filterable="false" :options="categories" v-model="formInput.category_name" :reduce="categories => categories.id" @search="onSearchCategory" @input="setSelectedCategory">
                        <template slot="no-options"> type to search categories... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.logo' style="width: 1rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-12">
                    <label class="text text-info">Name</label>
                    <input type="text" class="form-control" placeholder="Enter the name" required v-model="formInput.name" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label class="text text-info mb-3">Images</label>
                    <b-form-file accept="image/*" v-on:change="onFileChange"></b-form-file>
                    <div class="row mt-5">
                        <div class="col-lg-2 col-md-4" v-for="(v,i) in images" :key="i">
                            <h6 v-if="v.file_json !== null">{{ JSON.parse(v.file_json).files.size }}</h6>
                            <div class="container-overlay">
                                <img :src="v.file_url" alt="" class="image-overlay">
                                <div @click="handleImageDelete(v.id)" class="overlay">
                                    <div class="text-overlay"> Delete </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Compressor from 'compressorjs';

export default {
    name: "EditBrand",
    props: {
        propsRow: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            principles: [],
            categories: [],
            formInput: {},
            files: [],
            images: []
        };
    },
    watch: {
        propsRow: {
            immediate: true,
            handler() {
                this.formInput = Object.assign({}, this.propsRow);

                if (this.propsRow.id !== undefined) {
                    this.getDetailBrand()
                }
            },
        },
    },
    methods: {
        async onFileChange(e) {
            const files = e.target.files[0]
            if (!files) return;
            const shelter = []

            // 180 x 144
            new Compressor(files, {
                quality: 0.8,
                width: 180,
                height: 144,
                success(result) {
                    const reader = new FileReader();
                    reader.readAsDataURL(result);

                    reader.onload = () => {
                        shelter.push({
                            content: reader.result,
                            size: '180x144',
                            name: result.name
                        });
                    };
                    // reader.readAsDataURL(result)
                }
            })

            // 360 x 270
            new Compressor(files, {
                quality: 0.8,
                width: 360,
                height: 270,
                success(result) {
                    const reader2 = new FileReader();
                    reader2.readAsDataURL(result);

                    reader2.onload = () => {
                        shelter.push({
                            content: reader2.result,
                            size: '360x270',
                            name: result.name
                        });
                    };
                    // reader2.readAsDataURL(result)
                }
            })

            this.files = shelter
        },

        async onSearchPrinciple(query) {
            if (query.length > 0) {
                try {
                    const resp = await axios.post(
                        "dental-erp-service/principle", {
                            limit: 25,
                            page: 1,
                            search: query,
                            column: "",
                            ascending: false,
                        }, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        }
                    );

                    if (resp.data.error === false) {
                        this.principles = resp.data.data;
                    }
                } catch (error) {
                    // if (typeof error.response.data != undefined) {
                    //     return await this.$toastr.e(
                    //         error.response.data.message,
                    //         "Request Principle"
                    //     );
                    // }
                    // return await this.$toastr.e(error, "Request Principle");
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Request Principle", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                                return;
                            }
                            return this.$swal.fire("Request Principle ", error.response.data.message, "error");
                        }
                    }

                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                }
            }
            return false;
        },

        async onSearchCategory(query) {
            if (query.length > 0) {
                try {
                    const resp = await axios.post(
                        "dental-erp-service/category", {
                            limit: 25,
                            page: 1,
                            search: query,
                            column: "",
                            ascending: false,
                        }, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        }
                    );

                    if (resp.data.error === false) {
                        this.categories = resp.data.data;
                    }
                } catch (error) {
                    // if (typeof error.response.data != undefined) {
                    //     return await this.$toastr.e(
                    //         error.response.data.message,
                    //         "Request Category"
                    //     );
                    // }
                    // return await this.$toastr.e(error, "Request Category");
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Request Category", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                                return;
                            }
                            return this.$swal.fire("Request Category ", error.response.data.message, "error");
                        }
                    }

                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                }
            }
            return false;
        },
        
        setSelectedPrinciple(value) {
            this.formInput.id_principle = value;
        },
        
        setSelectedCategory(value) {
            this.formInput.id_category = value;
        },
        
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },

        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/brand/update",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide('modal-edit');
                    this.$swal.fire("Success", resp.data.message, "success");
                    if (this.files.length > 0) {
                        this.uploadFiles()
                    }

                    this.$parent.getData();
                }
            } catch (error) {
                // console.log(error);
                // this.$swal.fire("Error", error.response.data.message, "error");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },

        async uploadFiles() {

            this.$store.dispatch("setLoading", true);

            axios.post("dental-erp-service/brand/upload/image", {
                    id_ref: this.formInput.id,
                    file: this.files
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                })
                .then(resp => {
                    if (resp.data.error === false) {
                        return this.$swal.fire("Success", resp.data.message, "success");
                    }

                    this.$swal.fire("Error", resp.data.message, "error");
                })
                .catch(error => {
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Error", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                                return;
                            }
                            return this.$swal.fire("Request ", error.response.data.message, "error");
                        }
                    }

                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                })
                .then(() => {
                    this.$store.dispatch("setLoading", false);
                })
        },

        async getDetailBrand() {
            axios.post("dental-erp-service/brand/find", {
                    id: this.formInput.id
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                })
                .then(res => {
                    this.images = res.data.data.images
                })
        },

        async handleImageDelete(id) {

            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    axios.delete("dental-erp-service/category/delete/image", {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                            data: {
                                id: id
                            }
                        })
                        .then(resp => {
                            if (resp.data.error === false) {
                                return this.$swal.fire("Success", resp.data.message, "success");
                            }

                            this.$swal.fire("Error", resp.data.message, "error");
                        })
                        .catch(error => {
                            if (error.response != undefined) {
                                if (error.response.data != undefined) {
                                    if (error.response.data.error_auth != undefined) {
                                        this.$swal.fire("Error", error.response.data.message, "error");
                                        this.$store.dispatch("logOut").then(() => {
                                            this.$router.push("/login");
                                        });
                                        return;
                                    }
                                    return this.$swal.fire("Request ", error.response.data.message, "error");
                                }
                            }

                            this.$sentry.captureException(error);
                            this.$swal.fire("Error", error.message, "error");
                        })
                        .then(() => {
                            this.$store.dispatch("setLoading", false);
                            this.getDetailBrand()
                        })
                }
            })
        }
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}

.container-overlay {
    position: relative;
}

.image-overlay {
    display: block;
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 10%;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
    cursor: pointer;
}

.container-overlay:hover .overlay {
    opacity: 1;
}

.text-overlay {
    color: white;
    font-size: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

</style>
