<template>
<div>
    <b-modal id="modal-add" ref="my-modal-add" title="Form Add Product" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Part Number</label>
                    <input type="text" class="form-control" placeholder="Enter the part number" required v-model="formInput.part_number" />
                </div>
                <div class="form-group col-md-6 col-lg-8">
                    <label class="text text-info">Name</label>
                    <input type="text" class="form-control" placeholder="Enter the name" required v-model="formInput.name" />
                </div>
                <div class="form-group col-md-12 col-lg-12">
                    <label class="text text-info">Description</label>
                    <input type="text" class="form-control" placeholder="Enter the description" required v-model="formInput.description" />
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Principle</label>
                    <v-select label="name" :filterable="false" :options="principle" v-model="formInput.id_principle" :reduce="principle => principle.id" @search="onSearchPrinciple" @input="getBrand">
                        <template slot="no-options"> type to search principle... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.logo' style="width: 1rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Category</label>
                    <v-select label="name" :filterable="false" :options="category" v-model="formInput.id_category" :reduce="category => category.id" @search="onSearchCategory" @input="getBrand">
                        <template slot="no-options"> type to search category... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-4 col-lg-4">
                    <label class="text text-info">Brand</label>
                    <v-select label="name" :options="brand" v-model="formInput.id_brand" :reduce="brand => brand.id"></v-select>
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Unit</label>
                    <v-select v-model="formInput.piece" :options="pieces"></v-select>
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Safety Stock</label>
                    <input type="number" class="form-control" placeholder="Enter the safety stock" required v-model="formInput.safety_stock" />
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Weight</label>
                    <input type="number" class="form-control" required v-model="formInput.weight" />
                </div>
                <div class="form-group col-md-12">
                    <label class="text text-info">Image Product (Select Multiple File)</label>
                    <b-form-file accept="image/*" multiple v-model="files" v-on:change="onFileChange"></b-form-file>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "AddProduct",
    props: {
        pieces: {
            type: Array,
        },
    },
    data() {
        return {
            files: [],
            principle: [],
            warehouse: [],
            brand: [],
            category: [],
            formInput: {
                name: '',
                no_barcode: '',
                id_principle: '',
                id_category: '',
                id_brand: '',
                description: '',
                part_number: '',
                serial_number: '',
                safety_stock: 1,
                weight: 1,
                logo: []
            },
        };
    },
    methods: {
        async onFileChange() {
            this.formInput.logo = [];
            await this.$store.dispatch("setLoading", true);
            await this.files.forEach(element => {
                var reader = new FileReader();
                reader.readAsDataURL(element);
                reader.onload = () => {
                    this.formInput.logo.push({
                        content: reader.result.split(',')[1],
                        name: element.name
                    });
                };
            });
            await this.$store.dispatch("setLoading", false);
        },
        async onSearchPrinciple(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/principle", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.principle = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Principle"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Principle");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Principle", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Principle ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async onSearchCategory(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/category", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.category = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Category"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Category");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Category", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Category ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        // async setSelectedCategory(select) {
        //     // if (select == null) {
        //     //     return this.formInput.id_warehouse = '';
        //     // }
        //     getBrand();
        //     // return this.formInput.id_warehouse = warehouse.id;
        // },
        async getBrand() {
            this.formInput.id_brand = '';
            if (this.formInput.id_principle.length != 0 && this.formInput.id_category.length != 0) {
                try {
                    const resp = await axios.get(
                        `dental-erp-service/brand/option?id_principle=${this.formInput.id_principle}&id_category=${this.formInput.id_category}`, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        }
                    );

                    if (resp.data.error === false) {
                        if (resp.data.data.length == 0) {
                            return await this.$toastr.w("Brand data does not exist", "Request Brand");
                        }
                        this.brand = resp.data.data;
                    }
                } catch (error) {
                    // if (typeof error.response.data != undefined) {
                    //     return await this.$toastr.e(
                    //         error.response.data.message,
                    //         "Request Brand"
                    //     );
                    // }
                    // return await this.$toastr.e(error, "Request Brand");
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Request Brand", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                                return;
                            }
                            return this.$swal.fire("Request Brand ", error.response.data.message, "error");
                        }
                    }

                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                }
            }
        },
        async handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/product/store",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide("modal-add");
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.$parent.getData();

                    this.files = [];
                    this.principle = [];
                    this.warehouse = [];
                    this.brand = [];
                    this.category = [];
                    this.formInput.name = '';
                    this.formInput.no_barcode = ''
                    this.formInput.id_principle = ''
                    this.formInput.id_category = ''
                    this.formInput.id_brand = ''
                    this.formInput.description = ''
                    this.formInput.part_number = ''
                    this.formInput.serial_number = ''
                    this.formInput.safety_stock = ''
                    this.formInput.logo = []

                }
            } catch (error) {
                // console.log(error);
                // this.$swal.fire("Error", error.response.data.message, "error");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
