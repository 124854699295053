<template>
<div>
    <b-modal id="modal-add" ref="my-modal-add" title="Form Add Brand" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label class="text text-info">Principle</label>
                    <v-select label="name" :filterable="false" :options="principles" v-model="formInput.id_principle" :reduce="principle => principle.id" @search="onSearchPrinciple">
                        <template slot="no-options"> type to search principle... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.logo' style="width: 1rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">Category</label>
                    <v-select label="name" :filterable="false" :options="categories" v-model="formInput.id_category" :reduce="categories => categories.id" @search="onSearchCategory">
                        <template slot="no-options"> type to search categories... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.logo' style="width: 1rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-12">
                    <label class="text text-info">Name</label>
                    <input type="text" class="form-control" placeholder="Enter the name" required v-model="formInput.name" />
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "AddBrand",
    data() {
        return {
            principles: [],
            categories: [],
            formInput: {
                name: "",
            },
        };
    },
    methods: {
        async onSearchPrinciple(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/principle", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.principles = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Principle"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Principle");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Principle", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Principle ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async onSearchCategory(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/category", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.categories = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Category"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Category");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Category", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Category ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/brand/store",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide("modal-add");
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.$refs.formAdd.reset();
                    this.$parent.getData();
                }
            } catch (error) {
                // console.log(error);
                // this.$swal.fire("Error", error.response.data.message, "error");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
