<template>
<div>
    <b-modal id="modal-edit" ref="my-modal-edit" title="Form Edit Warehouse" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk" @hidden="$parent.handleCancel">
        <form @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label class="text text-info">Name</label>
                    <input type="text" class="form-control" placeholder="Enter the name" required v-model="formInput.name" />
                    <label class="text-danger error">{{ validation.firstError('formInput.name') }}</label>
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">Province</label>
                    <v-select label="name" :options="provinces" :reduce="provinces => provinces.id" v-model="formInput.province" @input="setSelectedProvince"></v-select>
                    <label class="text-danger error">{{ validation.firstError('formInput.province') }}</label>
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">City</label>
                    <v-select :disabled="cities.length == 0" label="name" :options="cities" :reduce="cities => cities.id" v-model="formInput.city" @input="setSelectedCity"></v-select>
                    <label class="text-danger error">{{ validation.firstError('formInput.city') }}</label>
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">District</label>
                    <v-select :disabled="districts.length == 0" label="name" :options="districts" :reduce="districts => districts.id" v-model="formInput.district" @input="setSelectedDistrict"></v-select>
                    <label class="text-danger error">{{ validation.firstError('formInput.district') }}</label>
                </div>
                <div class="form-group col-md-12">
                    <label class="text text-info">Address</label>
                    <input type="text" class="form-control" placeholder="Enter the address" required v-model="formInput.address" />
                    <label class="text-danger error">{{ validation.firstError('formInput.address') }}</label>
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">Latitude</label>
                    <input type="text" class="form-control" placeholder="Enter the latitude" required v-model="formInput.latitude" />
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">Longitude</label>
                    <input type="text" class="form-control" placeholder="Enter the longitude" required v-model="formInput.longitude" />
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import {
    Validator
} from 'simple-vue-validator';
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "EditWarehouse",
    props: {
        propsRow: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            value: "",
            provinces: [],
            districts: [],
            cities: [],
            formInput: {},
        };
    },
    watch: {
        propsRow: {
            immediate: true,
            async handler() {
                this.formInput = Object.assign({}, this.propsRow);
                await this.getProvinces();
                await this.setSelectedProvince(this.formInput.id_province, true);
                await this.setSelectedCity(this.formInput.id_city, true);
                await this.setSelectedDistrict(this.formInput.id_district, true);
            },
        },
    },
    validators: {
        'formInput.name': function () {
            return Validator.value().required('Name is required');
        },
        'formInput.province': function (value) {
            return Validator.value(value).required('Province is required');
        },
        'formInput.city': function (value) {
            return Validator.value(value).required('City is required');
        },
        'formInput.district': function (value) {
            return Validator.value(value).required('District is required');
        },
        'formInput.address': function (value) {
            return Validator.value(value).required('Address is required');
        },
    },
    async mounted() {
        //await this.getProvinces();
    },
    methods: {
        async getProvinces() {
            try {
                const resp = await axios.post(
                    'dental-erp-service/area/regions', {
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.provinces = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Provinces"
                //     );
                // }
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Provinces", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Provinces ", error.response.data.message, "error");
                    }
                }
                return await this.$toastr.e(error, "Request Provinces");
            }
        },
        async setSelectedProvince(value, update = false) {
            try {

                if (update == false) {
                    this.formInput.city = '';
                    this.formInput.district = '';
                }

                if (value == null) {
                    this.cities = [];
                    this.districts = [];
                    this.formInput.city = '';
                    this.formInput.district = '';
                    return;
                }

                this.formInput.province = value;
                const resp = await axios.post(
                    `dental-erp-service/area/cities`, {
                        state_id: value,
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.cities = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Cities"
                //     );
                // }
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Cities", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Cities ", error.response.data.message, "error");
                    }
                }
                return await this.$toastr.e(error, "Request Cities");
            }
        },
        async setSelectedCity(value, update = false) {
            try {

                if (update == false) {
                    this.formInput.district = '';
                }

                if (value == null) {
                    this.formInput.city = '';
                    this.districts = [];
                    return;
                }

                this.formInput.city = value;
                const resp = await axios.post(
                    `dental-erp-service/area/districts`, {
                        city_id: value,
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.districts = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Districts"
                //     );
                // }
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Districts", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Districts ", error.response.data.message, "error");
                    }
                }
                return await this.$toastr.e(error, "Request Districts");
            }
        },
        async setSelectedDistrict(value) {
            if (value == null) {
                this.formInput.district = '';
                return;
            }
            this.formInput.district = value;
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/warehouse/update",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide('modal-edit');
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.$parent.getData();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
