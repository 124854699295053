<template>
<div>
    <b-modal id="modal-form" ref="my-modal-form" :title="title" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk" button-size="sm" @hidden="$parent.handleCancel">
        <form @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6 col-lg-6">
                    <label class="text text-info">Bank</label>
                    <v-select label="name" :filterable="false" :options="banks" v-model="formInput.bank_name" @search="onSearchBank" @input="setSelectedBank">
                        <template slot="no-options"> type to search bank... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.logo_url' style="width: 1.5rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.logo_url' style="width: 1rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-6 col-lg-6">
                    <label class="text text-info">Account Number</label>
                    <input type="text" class="form-control" placeholder="Enter the account number" required v-model="formInput.account_number" />
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Account Name</label>
                    <input type="text" class="form-control" placeholder="Enter the account name" required v-model="formInput.name" />
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Account Branch</label>
                    <input type="text" class="form-control" placeholder="Enter the account branch" required v-model="formInput.branch" />
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">NPWP</label>
                    <input type="text" class="form-control" placeholder="Enter the npwp" required v-model="formInput.npwp" />
                </div>
                <div class="form-group col-md-6 col-lg-3">
                    <label class="text text-info">Default</label>
                    <b-form-checkbox id="checkbox-1" v-model="formInput.is_default" name="checkbox-1" :value="true" :unchecked-value="false">
                        Make this account the default
                    </b-form-checkbox>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "FormPayment",
    data() {
        return {
            title: 'Add Payment Account',
            banks: [],
            selected: {
                bank: null
            },
            formInput: {
                name: "",
                is_default: false,
                id_bank: "",
                branch: "",
                account_number: "",
                bank_name: "",
                npwp: "",
                setup: ""
            },
        };
    },
    props: {
        propsRow: {
            type: Object,
        },
    },
    watch: {
        propsRow: {
            immediate: true,
            handler() {
                if (this.propsRow.id != undefined) {
                    this.title = 'Update Payment Account'
                    this.formInput = Object.assign({}, this.propsRow);
                } else {
                    this.title = 'Add Payment Account';
                    this.formInput = {
                        name: "",
                        is_default: false,
                        id_bank: "",
                        branch: "",
                        account_number: "",
                        bank_name: "",
                        npwp: "",
                        setup: ""
                    }
                }
            },
        },
        'formInput.setup': {
            immediate: true,
            handler() {
                if (this.formInput.setup == "Default Account") {
                    this.formInput.is_default = true;
                }
            }
        }
    },
    methods: {
        async onSearchBank(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/bank", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.banks = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Bank", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Bank ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedBank(value) {
            if (value == null) {
                this.formInput.id_bank = '';
                this.formInput.bank_name = '';
                return;
            }

            this.formInput.bank_name = value.name;
            this.formInput.id_bank = value.id;
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    this.formInput.id === undefined ? "dental-erp-service/payment_account/store" : "dental-erp-service/payment_account/update",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$bvModal.hide("modal-form");
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.formInput = {
                        name: "",
                        id_bank: "",
                        branch: "",
                        account_number: "",
                        bank_name: "",
                        npwp: ""
                    };
                    this.$parent.getData();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
