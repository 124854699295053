<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <router-link class="btn btn-outline-info" to="area-admin" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to List Area
        </router-link>
    </div>
    <div class="row">
        <div class="form-group col-md-4 col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-3">
                        <div class="row">
                            <div>
                                <label class="text text-info ml-3">Area Name</label>
                            </div>
                            <div class="col-10">
                                <input disabled type="text" class="form-control" placeholder="Enter area name" required v-model="data.name" />
                            </div>
                            <div class="col-2">
                                <b-button class="mt-2" @click="propsEdit = data" v-b-modal.modal-edit title="Edit Area Name" variant="outline-info" size="sm"><i class="fa fa-edit"></i></b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Sales in Area</h4>
                    </div>
                    <div class="text-right">
                        <b-button v-b-modal.modal-add-sales title="Add Sales" class="btn btn-success fa fa-plus" size="sm"></b-button>
                    </div>
                </div>
                <div>
                    <div class="form-row">
                        <div class="pt-4 table-responsive">
                            <table class="table success-table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(value, index) in data.sales" :key="index">
                                        <td>
                                            <img :src="value.sales_by_data.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                            {{value.sales_by_data.first_name}}
                                        </td>
                                        <td>
                                            <div class="text-center">
                                                <button class="btn btn-danger shadow btn-xs sharp" type="button" @click="removeSales(value)"><i class="fa fa-trash"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Area Coverage</h4>
                    </div>
                    <div class="text-right">
                        <b-button v-b-modal.modal-add-coverage class="btn btn-success fa fa-plus" title="Add Coverage" size="sm"></b-button>
                    </div>
                </div>
                <div>
                    <div class="form-row">
                        <div class="pt-4 table-responsive">
                            <table class="table success-table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>Province</th>
                                        <th>City</th>
                                        <th>District</th>
                                        <th>Village</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(value, index) in data.coverages" :key="index">
                                        <td>{{value.province.name}}</td>
                                        <td>{{value.regency.name}}</td>
                                        <td>{{value.district.name}}</td>
                                        <td>{{value.village.name}}</td>
                                        <td>
                                            <div class="text-center">
                                                <button class="btn btn-danger shadow btn-xs sharp" type="button" @click="removeCoverage(value)"><i class="fa fa-trash"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <EditArea :props-row="propsEdit" />

    <b-modal id="modal-add-sales" ref="my-modal-add-sales" title="Add Sales" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="SubmitSales">
        <form ref="formAdd">
            <div class="form-row">
                <div class="form-group col-md-6 col-lg-12">
                    <label class="text text-info">Sales User</label>
                    <v-select :options="sales_users" label="first_name" v-model="sales_user" @input="handleSalesUser">
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.profile' style="width: 1.5rem;height: auto;" />
                                {{ option.first_name + ' ' + option.last_name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.profile' style="width: 1rem;height: auto;" />
                                {{ option.first_name + ' ' + option.last_name }}
                            </div>
                        </template>
                    </v-select>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-add-coverage" ref="my-modal-add-coverage" title="Add Sales" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="SubmitCoverage">
        <form ref="formAdd">
            <div class="form-row">
                <!-- <div class="form-group col-md-6 col-lg-12">
                    <label class="text text-info">Coverage Area</label>
                    <v-select :options="sales_users" label="first_name" v-model="sales_user" @input="handleSalesUser">
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.profile' style="width: 1.5rem;height: auto;" />
                                {{ option.first_name + ' ' + option.last_name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.profile' style="width: 1rem;height: auto;" />
                                {{ option.first_name + ' ' + option.last_name }}
                            </div>
                        </template>
                    </v-select>
                </div> -->
                <div class="form-group col-md-4 col-lg-6">
                    <label class="text text-info">Province</label>
                    <v-select label="name" :options="provinces" :reduce="provinces => provinces.id" v-model="selectedarea.id_province" @input="setSelectedProvince"></v-select>
                    <!-- <label class="text-danger error">{{ validation.firstError('selectedarea.id_province') }}</label> -->
                </div>
                <div class="form-group col-md-4 col-lg-6">
                    <label class="text text-info">City</label>
                    <v-select :disabled="cities.length == 0" label="name" :options="cities" :reduce="cities => cities.id" v-model="selectedarea.id_regency" @input="setSelectedCity"></v-select>
                    <!-- <label class="text-danger error">{{ validation.firstError('selectedarea.id_regency') }}</label> -->
                </div>
                <div class="form-group col-md-4 col-lg-6">
                    <label class="text text-info">District</label>
                    <v-select :disabled="districts.length == 0" label="name" :options="districts" :reduce="districts => districts.id" v-model="selectedarea.id_district" @input="setSelectedDistrict"></v-select>
                    <!-- <label class="text-danger error">{{ validation.firstError('selectedarea.id_district') }}</label> -->
                </div>
                <div class="form-group col-md-4 col-lg-6">
                    <label class="text text-info">Village</label>
                    <v-select :disabled="villages.length == 0" label="name" :options="villages" :reduce="villages => villages.id" v-model="selectedarea.id_village" @input="setSelectedVillage"></v-select>
                    <!-- <label class="text-danger error">{{ validation.firstError('selectedarea.id_village') }}</label> -->
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import EditArea from "./EditArea";

import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "DetailArea",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Area | %s',
    },
    components: {
        EditArea
    },
    data() {
        return {
            formInput: {
                id_area: '',
                id_sales: ''
            },
            propsEdit: {},
            data: [],
            sales_users: [],
            selectedarea: {
                id_area: '',
                id_province: "",
                id_village: "",
                id_district: "",
                id_regency: ""
            },
            countries: [],
            provinces: [],
            cities: [],
            districts: [],
            villages: [],
            sales_user: '',
        };
    },

    async mounted() {
        await this.getProvinces();
    },

    async created() {
        this.onLoad();
        this.getSalesUsers();
    },

    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('area-admin')
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/area/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {

                    return this.data = resp.data.data;
                }

                return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('area-admin')

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Sales Order", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Order ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async handleSalesUser(value) {
            if (value == null) {
                this.formInput.id_sales = '';
                return false;
            }
            this.formInput.id_sales = value.id;
        },

        async getSalesUsers() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.get(
                    'user-service/auth/users-by-role?role=sales&is_access=1', {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.sales_users = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Users", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Sales Users", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async getProvinces() {
            try {
                const resp = await axios.post(
                    'dental-erp-service/area/regions', {
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.provinces = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Provinces", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Provinces ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedProvince(value, update = false) {
            try {
                if (update == false) {
                    this.selectedarea.id_regency = '';
                    this.selectedarea.id_district = '';
                    this.selectedarea.id_village = '';
                }

                if (value == null) {
                    this.cities = [];
                    this.districts = [];
                    this.villages = [];
                    this.selectedarea.id_regency = '';
                    this.selectedarea.id_district = '';
                    this.selectedarea.id_village = '';
                    return;
                }

                this.selectedarea.id_province = value;
                const resp = await axios.post(
                    `dental-erp-service/area/cities`, {
                        state_id: value,
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.cities = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Cities", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Cities ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedCity(value, update = false) {
            try {

                if (update == false) {
                    this.selectedarea.id_district = '';
                    this.selectedarea.id_village = '';
                }

                if (value == null) {
                    this.selectedarea.id_regency = '';
                    this.districts = [];
                    this.villages = [];
                    return;
                }

                this.selectedarea.id_regency = value;
                const resp = await axios.post(
                    `dental-erp-service/area/districts`, {
                        city_id: value,
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.districts = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Districts", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Districts ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedDistrict(value, update = false) {
            try {

                if (update == false) {
                    this.selectedarea.id_village = '';
                }

                if (value == null) {
                    this.villages = [];
                    this.selectedarea.id_district = '';
                    return;
                }

                this.selectedarea.id_district = value;
                const resp = await axios.post(
                    `dental-erp-service/area/villages`, {
                        district_id: value,
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.villages = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Villages", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Villages ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedVillage(value) {
            if (value == null) {
                this.selectedarea.id_village = '';
                return;
            }
            this.selectedarea.id_village = value;
        },

        handleCancel() {
            this.propsEdit = {};
        },

        async removeSales(value) {
            this.$swal.fire({
                title: 'Are you sure delete this sales?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.delete(
                            "dental-erp-service/area/sales/destroy", {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                                data: {
                                    id: value.id
                                }
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.onLoad();
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },

        async removeCoverage(value) {
            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.delete(
                            "dental-erp-service/area/coverage/destroy", {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                                data: {
                                    id: value.id
                                }
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.onLoad();
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },

        async SubmitSales() {
            this.formInput.id_area = this.$route.query.id
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/area/sales/store",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.onLoad();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },

        async SubmitCoverage() {
            this.selectedarea.id_area = this.$route.query.id
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/area/coverage/store",
                    this.selectedarea, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.selectedarea = {
                        id_province: "",
                        id_village: "",
                        id_district: "",
                        id_regency: ""
                    };
                    this.provinces = [];
                    this.cities = [];
                    this.districts = [];
                    this.villages = [];
                    this.onLoad();
                    this.getProvinces();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        }

    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
