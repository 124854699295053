<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-4 col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-3">
                        <label class="text text-info">Area Name</label>
                        <input type="text" class="form-control" placeholder="Enter area name" required v-model="formInput.name" />
                        <label class="text-danger error">{{ validation.firstError('formInput.name') }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Select Sales</h4>
                    </div>
                </div>
                <div class="card-body">
                    <form ref="formAdd">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-6">
                                <label class="text text-info">Select Sales</label>
                                <v-select :options="sales_users" v-model="sales_user" label="first_name" :filterable="false">
                                    <template slot="no-options"> type to search sales... </template>
                                </v-select>
                            </div>
                        </div>
                    </form>
                    <div class="mt-4">
                        <button class="btn btn-outline-info mb-1 mr-1" :disabled="sales_user == null" @click="AddSales(sales_user)"> Assign Sales </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Select Area Coverage</h4>
                    </div>
                </div>
                <div class="card-body">
                    <form ref="formAdd">
                        <div class="form-row">
                            <div class="form-group col-md-4 col-lg-6">
                                <label class="text text-info">Province</label>
                                <v-select label="name" :options="provinces" v-model="selectedarea.id_province" @input="setSelectedProvince"></v-select>
                            </div>
                            <div class="form-group col-md-4 col-lg-6">
                                <label class="text text-info">City</label>
                                <v-select :disabled="cities.length == 0" label="name" :options="cities" v-model="selectedarea.id_regency" @input="setSelectedCity"></v-select>
                            </div>
                            <div class="form-group col-md-4 col-lg-6">
                                <label class="text text-info">District</label>
                                <v-select :disabled="districts.length == 0" label="name" :options="districts" v-model="selectedarea.id_district" @input="setSelectedDistrict"></v-select>
                            </div>
                            <div class="form-group col-md-4 col-lg-6">
                                <label class="text text-info">Village</label>
                                <v-select :disabled="villages.length == 0" label="name" :options="villages" v-model="selectedarea.id_village" @input="setSelectedVillage"></v-select>
                            </div>
                        </div>
                    </form>
                    <div>
                        <button class="btn btn-outline-info mb-1 mr-1" :disabled="selectedarea.id_province == null || selectedarea.id_province.length == 0" @click="AddArea(selectedarea)"> Assign Area </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card" v-if="salesshelter.length > 0">
                <div class="card-body">
                    <div class="form-row">
                        <div class="pt-4 table-responsive">
                            <table class="table success-table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(value, index) in salesshelter" :key="index">
                                        <td>{{value.name}}</td>
                                        <td>
                                            <div class="text-center">
                                                <button class="btn btn-danger shadow btn-xs sharp" type="button" @click="removeSales(index)"><i class="fa fa-trash"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card" v-if="areashelter.length > 0">
                <div class="card-body">
                    <div class="form-row">
                        <div class="pt-4 table-responsive">
                            <table class="table success-table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th>Province</th>
                                        <th>City</th>
                                        <th>Disctrict</th>
                                        <th>Village</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(value, index) in areashelter" :key="index">
                                        <td>{{value.id_province.name}}</td>
                                        <td>{{value.id_regency.name}}</td>
                                        <td>{{value.id_district.name}}</td>
                                        <td>{{value.id_village.name}}</td>
                                        <td>
                                            <div class="text-center">
                                                <button class="btn btn-danger shadow btn-xs sharp" type="button" @click="removeArea(index)"><i class="fa fa-trash"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button :disabled="salesshelter.length == 0 || areashelter.length == 0" class="btn btn-outline-success ml-3 float-right" @click="handleSubmit()"> Assign Area to Sales </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
    Validator
} from 'simple-vue-validator';

export default {
    name: "AddArea",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Add Area | %s',
    },
    props: {
        pieces: {
            type: Array,
        },
    },
    data() {
        return {
            selected: [],
            salesshelter: [],
            areashelter: [],
            countries: [],
            provinces: [],
            cities: [],
            districts: [],
            villages: [],
            sales_users: [],
            sales_user: null,
            selectedarea: {
                id_province: [],
                id_village: [],
                id_district: [],
                id_regency: []
            },
            formInput: {
                name: "",
                sales: [],
                coverages: [],
                country: 102,
                id_province: "",
                id_village: "",
                id_district: "",
                id_regency: ""
            },
        };
    },

    async mounted() {
        await this.getProvinces();
    },

    async created() {
        this.getSalesUsers();
    },

    validators: {
        'formInput.name': function (value) {
            return Validator.value(value).required('Area Name is required');
        },
    },

    methods: {
        async getSalesUsers() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.get(
                    'user-service/auth/users-by-role?role=sales&is_access=1', {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.sales_users = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Users", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Sales Users", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },
        async AddSales(sales) {
            let exist = false;

            for (let i = 0; i < this.salesshelter.length; i++) {
                if (this.salesshelter[i].id == sales.id) {
                    exist = true;
                    break;
                }
            }

            if (exist === false) {
                this.salesshelter.push({
                    id: sales.id,
                    name: sales.first_name
                })

                this.sales_user = null
            } else {
                return this.$swal.fire("Sales already added", '', "warning");
            }
        },
        async removeSales(index) {
            this.salesshelter.splice(index, 1);
        },
        async AddArea(area) {

            // this.$validate().then(function (success) {
            //     if (!success) {
            //         return false;
            //     }
            // });

            if (
                Object.keys(area.id_province).length > 0 &&
                Object.keys(area.id_regency).length > 0 &&
                Object.keys(area.id_district).length > 0 &&
                Object.keys(area.id_village).length > 0
            ) {
                let exist = false;

                for (let i = 0; i < this.areashelter.length; i++) {
                    if (this.areashelter[i].id_village == area.id_village) {
                        exist = true;
                        break;
                    }
                }

                if (exist === false) {
                    this.areashelter.push({
                        id_province: area.id_province,
                        id_regency: area.id_regency,
                        id_district: area.id_district,
                        id_village: area.id_village
                    })
                } else {
                    return this.$swal.fire("Area already added", '', "warning");
                }
            }
        },
        async removeArea(index) {
            this.areashelter.splice(index, 1);
        },
        async getProvinces() {
            try {
                const resp = await axios.post(
                    'dental-erp-service/area/regions', {
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.provinces = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Provinces", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Provinces ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedProvince(value, update = false) {
            try {
                if (update == false) {
                    this.selectedarea.id_regency = [];
                    this.selectedarea.id_district = [];
                    this.selectedarea.id_village = [];
                }

                if (value == null) {
                    this.cities = [];
                    this.districts = [];
                    this.villages = [];
                    this.selectedarea.id_regency = [];
                    this.selectedarea.id_district = [];
                    this.selectedarea.id_village = [];
                    return;
                }

                this.selectedarea.id_province = value;
                const resp = await axios.post(
                    `dental-erp-service/area/cities`, {
                        state_id: value.id,
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.cities = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Cities", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Cities ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedCity(value, update = false) {
            try {

                if (update == false) {
                    this.selectedarea.id_district = [];
                    this.selectedarea.id_village = [];
                }

                if (value == null) {
                    this.selectedarea.id_regency = [];
                    this.districts = [];
                    this.villages = [];
                    return;
                }

                this.selectedarea.id_regency = value;
                const resp = await axios.post(
                    `dental-erp-service/area/districts`, {
                        city_id: value.id,
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.districts = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Districts", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Districts ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedDistrict(value, update = false) {
            try {

                if (update == false) {
                    this.selectedarea.id_village = [];
                }

                if (value == null) {
                    this.villages = [];
                    this.selectedarea.id_district = [];
                    return;
                }

                this.selectedarea.id_district = value;
                const resp = await axios.post(
                    `dental-erp-service/area/villages`, {
                        district_id: value.id,
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.villages = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Villages", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Villages ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedVillage(value) {
            if (value == null) {
                this.selectedarea.id_village = [];
                return;
            }
            this.selectedarea.id_village = value;
        },
        onRowSelected(items) {
            this.selected = items
        },
        async handleSubmit() {

            let validation = await this.$validate();
            if (!validation) {
                return false;
            }

            this.formInput.coverages = this.areashelter;
            this.formInput.sales = this.salesshelter;

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {

                        let arr = [];

                        await this.areashelter.forEach(function (value) {
                            arr.push({
                                id_province: value.id_province.id,
                                id_regency: value.id_regency.id,
                                id_district: value.id_district.id,
                                id_village: value.id_village.id
                            });
                        });

                        this.formInput.coverages = arr;

                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            "dental-erp-service/area/store",
                            this.formInput, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            window.history.length > 2 ? this.$router.go(-1) : this.$router.push('area-admin')
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Error ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
