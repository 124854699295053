<template>
<div>
    <b-modal id="modal-edit" ref="my-modal-edit" title="Form Edit Category Principle" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk" @hidden="$parent.handleCancel">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label class="text text-info">Principle</label>
                    <v-select label="name" :filterable="false" :options="principle" v-model="formInput.principle_name" :reduce="principle => principle.id" @search="onSearchPrinciple" @input="setSelectedPrinciple">
                        <template slot="no-options"> type to search principle... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.logo' style="width: 1rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-12">
                    <label class="text text-info">Name</label>
                    <input type="text" class="form-control" placeholder="Enter the name" required v-model="formInput.name" />
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "EditCategoryPrinciple",
    props: {
        propsRow: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            principle: [],
            formInput: {},
        };
    },
    watch: {
        propsRow: {
            immediate: true,
            handler() {
                this.formInput = Object.assign({}, this.propsRow);
            },
        },
    },
    methods: {
        async onSearchPrinciple(query) {
            if (query.length > 0) {
                try {
                    const resp = await axios.post(
                        "dental-erp-service/principle", {
                            limit: 25,
                            page: 1,
                            search: query,
                            column: "",
                            ascending: false,
                        }, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        }
                    );

                    if (resp.data.error === false) {
                        this.principle = resp.data.data;
                    }
                } catch (error) {
                    // if (typeof error.response.data != undefined) {
                    //     return await this.$toastr.e(
                    //         error.response.data.message,
                    //         "Request Principle"
                    //     );
                    // }
                    // return await this.$toastr.e(error, "Request Principle");
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Request Principle", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                                return;
                            }
                            return this.$swal.fire("Request Principle ", error.response.data.message, "error");
                        }
                    }

                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                }
            }
            return false;
        },
        setSelectedPrinciple(value) {
            this.formInput.id_principle = value;
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/category_principle/update",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide('modal-edit');
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.$parent.getData();
                }
            } catch (error) {
                // console.log(error);
                // this.$swal.fire("Error", error.response.data.message, "error");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}
</style>
