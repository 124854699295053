<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>

        <b-button variant="outline-info" to="customer-admin">
            <b-icon icon="arrow-left" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Back to Customer List
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <form-wizard @on-complete="onComplete" :title="doUpdate ? 'Form Update Customer' : 'Form Add Customer' " subtitle="" color="#2f4cdd" stepSize="lg">
                        <tab-content title="Customer" icon="ti-notepad" :before-change="beforeTabSwitch">
                            <form ref="formAdd" @submit.prevent="handleSubmit" class="mt-5">
                                <div class="form-row">
                                    <div class="form-group col-md-4 col-lg-2">
                                        <label class="text text-info">Organization name</label>
                                        <input type="text" class="form-control" placeholder="Enter the organization" required v-model="formInput.organization" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.organization') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-2">
                                        <label class="text text-info">NPWP</label>
                                        <input type="text" class="form-control" placeholder="Enter the npwp" v-model="formInput.npwp" />
                                        <!-- <label class="text-danger error">{{ validation.firstError('formInput.npwp') }}</label> -->
                                    </div>
                                    <!-- <div class="form-group col-md-4 col-lg-2">
                                        <label class="text text-info">Full Name</label>
                                        <input type="text" class="form-control" placeholder="Enter the full name" required v-model="formInput.fullname" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.fullname') }}</label>
                                    </div> -->
                                    <div class="form-group col-md-4 col-lg-2">
                                        <label class="text text-info">Status</label>
                                        <v-select :options="status" v-model="formInput.status"></v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.status') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-2">
                                        <label class="text text-info">Email</label>
                                        <input type="email" class="form-control" placeholder="Enter the email" required v-model="formInput.email" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.email') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-2">
                                        <label class="text text-info">Website</label>
                                        <input type="text" class="form-control" placeholder="Enter the website" required v-model="formInput.website" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.website') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-2">
                                        <label class="text text-info">Phone Number</label>
                                        <input type="text" class="form-control" placeholder="Enter the phone number" required v-model="formInput.phone_number" @keypress="isNumber($event)" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.phone_number') }}</label>
                                    </div>
                                    <!-- <div class="form-group col-md-4 col-lg-2">
                                        <label class="text text-info">Country</label>
                                        <v-select label="name" :filterable="false" :options="countries" @search="onSearchCountry" @input="setSelectedCountry">
                                            <template slot="no-options"> type to search Country... </template>
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    {{ option.name }}
                                                </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="selected d-center">
                                                    {{ option.name }}
                                                </div>
                                            </template>
                                        </v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.country') }}</label>
                                    </div> -->
                                    <div class="form-group col-md-4 col-lg-3">
                                        <label class="text text-info">Province</label>
                                        <v-select label="name" :options="provinces" :reduce="provinces => provinces.id" v-model="formInput.province" @input="setSelectedProvince"></v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.province') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3">
                                        <label class="text text-info">City</label>
                                        <v-select :disabled="cities.length == 0" label="name" :options="cities" :reduce="cities => cities.id" v-model="formInput.city" @input="setSelectedCity"></v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.city') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3">
                                        <label class="text text-info">District</label>
                                        <v-select :disabled="districts.length == 0" label="name" :options="districts" :reduce="districts => districts.id" v-model="formInput.district" @input="setSelectedDistrict"></v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.district') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3">
                                        <label class="text text-info">Village</label>
                                        <v-select :disabled="villages.length == 0" label="name" :options="villages" :reduce="villages => villages.id" v-model="formInput.village" @input="setSelectedVillage"></v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.village') }}</label>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label class="text text-info">Address</label>
                                        <textarea class="form-control" rows="4" placeholder="Enter the address" v-model="formInput.address"></textarea>
                                        <label class="text-danger error">{{ validation.firstError('formInput.address') }}</label>
                                    </div>
                                    <div class="form-group col-md-6 col-lg-!6" v-if="!doUpdate">
                                        <label class="text text-info">Upload Profile</label>
                                        <b-form-file accept="image/*" v-on:change="onFileChange" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."></b-form-file>
                                    </div>
                                </div>
                            </form>
                        </tab-content>
                        <tab-content title="PIC" icon="ti-user">
                            <div class="form-head d-flex mb-3 align-items-start">
                                <div class="mr-auto d-none d-lg-block"></div>
                                <b-button variant="outline-info" v-b-modal.modal-add @click="clearPIC">
                                    <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Add PIC
                                </b-button>
                            </div>
                            <hr class="mt-3 mb-3">
                            <div class="table-responsive" v-show="formInput.pic.length > 0">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th><strong>NPWP</strong></th>
                                            <th><strong>NAME</strong></th>
                                            <th><strong>POSITION</strong></th>
                                            <th><strong>EMAIL</strong></th>
                                            <th><strong>PHONE NUMBER</strong></th>
                                            <th><strong>DATE OF BIRTH</strong></th>
                                            <th><strong>ADDRESS</strong></th>
                                            <th><strong>NOTES</strong></th>
                                            <th><strong>ACTION</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!doUpdate">
                                        <tr v-for="(pic, index) in formInput.pic" :key="index">
                                            <td><strong>{{ pic.pic_npwp }}</strong></td>
                                            <td>{{ pic.pic_name }}</td>
                                            <td>{{ pic.pic_title }}</td>
                                            <td>{{ pic.pic_email }}</td>
                                            <td>{{ pic.pic_phone_number }}</td>
                                            <td>{{ pic.pic_date_birth }}</td>
                                            <td>{{ pic.pic_address }}</td>
                                            <td>{{ pic.pic_notes }}</td>
                                            <td>
                                                <div class="d-flex">
                                                    <button type="button" class="btn btn-danger shadow btn-xs sharp" @click="deletePIC(index)"><i class="fa fa-trash"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(pic, index) in formInput.pic" :key="index">
                                            <td><strong>{{ pic.npwp }}</strong></td>
                                            <td>{{ pic.name }}</td>
                                            <td>{{ pic.title }}</td>
                                            <td>{{ pic.email }}</td>
                                            <td>{{ pic.phone_number }}</td>
                                            <td>{{ pic.date_birth }}</td>
                                            <td>{{ pic.address }}</td>
                                            <td>{{ pic.notes }}</td>
                                            <td>
                                                <div class="d-flex">
                                                    <button type="button" class="btn btn-info shadow btn-xs sharp mr-1" @click="editPIC(pic)"><i class="fa fa-pencil"></i></button>
                                                    <button type="button" class="btn btn-danger shadow btn-xs sharp" @click="destoryPIC(pic)"><i class="fa fa-trash"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </tab-content>
                        <!-- <tab-content title="Save" icon="ti-check">
                            Yuhuuu! This seems pretty damn simple
                        </tab-content> -->
                    </form-wizard>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-add" ref="my-modal-add" title="Form PIC" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk">
        <form ref="formAdd" @submit.prevent="addPIC">
            <div class="form-row">
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">NPWP</label>
                    <input type="text" class="form-control" placeholder="Enter the PIC npwp" v-model="pic.pic_npwp" />
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Name</label>
                    <input type="text" class="form-control" placeholder="Enter the PIC name" required v-model="pic.pic_name" />
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Position</label>
                    <input type="text" class="form-control" placeholder="Enter the PIC position" required v-model="pic.pic_title" />
                    <!-- <v-select :options="positions" v-model="pic.pic_title"></v-select> -->
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Email</label>
                    <input type="email" class="form-control" placeholder="Enter the PIC email" required v-model="pic.pic_email" />
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Phone Number</label>
                    <input type="text" class="form-control" placeholder="Enter the PIC phone number" required v-model="pic.pic_phone_number" @keypress="isNumber($event)" />
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Date of Birth</label>
                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="pic.pic_date_birth" initial-date="1990-01-01"></b-form-datepicker>
                </div>
                <div class="form-group col-md-6 col-lg-6">
                    <label class="text text-info">Address</label>
                    <input type="text" class="form-control" placeholder="Enter the PIC address" required v-model="pic.pic_address" />
                </div>
                <div class="form-group col-md-6 col-lg-12">
                    <label class="text text-info">Notes</label>
                    <textarea class="form-control" rows="4" placeholder="Enter the notes" v-model="pic.pic_notes"></textarea>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import {
    Validator
} from 'simple-vue-validator';
// import "bootstrap-vue/dist/bootstrap-vue.css";

import {
    FormWizard,
    TabContent
} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
    name: "FormCustomer",
    components: {
        FormWizard,
        TabContent
    },
    data() {
        return {
            countries: [],
            provinces: [],
            cities: [],
            districts: [],
            villages: [],
            doUpdate: false,
            status: [],
            // positions: ['PPK'],
            pic: {
                pic_npwp: '',
                pic_name: '',
                pic_title: '',
                pic_email: '',
                pic_phone_number: '',
                pic_date_birth: '',
                pic_address: '',
                pic_notes: '',
            },
            formInput: {
                npwp: "",
                organization: "",
                phone_number: "",
                country: 102,
                province: "",
                village: "",
                district: "",
                city: "",
                address: "",
                status: "",
                email: "",
                website: "",
                profile_filename: "",
                profile_content: "",
                pic: []
            },
        };
    },
    // async created() {

    // },
    async mounted() {
        await this.getStatusCode();
        await this.getProvinces();

        if (this.$route.query.id) {
            try {
                this.doUpdate = true;
                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    'dental-erp-service/customer/find', {
                        id: this.$route.query.id
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.formInput = resp.data.data;
                    await this.setSelectedProvince(this.formInput.province, true);
                    await this.setSelectedCity(this.formInput.city, true);
                    await this.setSelectedDistrict(this.formInput.district, true);
                    return;
                }

                await this.$toastr.e(
                    resp.data.message,
                    "Request Customer"
                );
                return this.$router.push('customer-admin');
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Customer"
                //     );
                //     return this.$router.push('CustomerAdmin');
                // }
                // await this.$toastr.e(error, "Request Customer");
                // return this.$router.push('CustomerAdmin');
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Customer", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Customer ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        }
    },
    validators: {
        // 'formInput.npwp': function (value) {
        //     return Validator.value(value).required('NPWP is required');
        // },
        // 'formInput.fullname': function (value) {
        //     return Validator.value(value).required('Full name is required');
        // },
        'formInput.organization': function (value) {
            return Validator.value(value).required('Organization is required');
        },
        'formInput.phone_number': function (value) {
            return Validator.value(value).required('Phone number is required').digit();
        },
        'formInput.address': function (value) {
            return Validator.value(value).required('Address is required');
        },
        'formInput.province': function (value) {
            return Validator.value(value).required('Province is required');
        },
        'formInput.city': function (value) {
            return Validator.value(value).required('City is required');
        },
        'formInput.district': function (value) {
            return Validator.value(value).required('District is required');
        },
        'formInput.village': function (value) {
            return Validator.value(value).required('Village is required');
        },
        'formInput.status': function (value) {
            return Validator.value(value).required('Status is required');
        },
    },
    methods: {
        async beforeTabSwitch() {
            let valid = await this.$validate();

            if (!valid) {
                return false;
            }
            return true;
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            var reader = new FileReader();
            reader.onload = () => {
                this.formInput.profile_content = reader.result.split(',')[1];
                this.formInput.profile_filename = files[0].name;
            };
            reader.readAsDataURL(files[0]);
        },
        async getProvinces() {
            try {
                const resp = await axios.post(
                    'dental-erp-service/area/regions', {
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.provinces = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Provinces"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Provinces");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Provinces", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Provinces ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async getStatusCode() {
            try {
                const resp = await axios.get(
                    'dental-erp-service/customer/status_codes', {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.status = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Status Code"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Status Code");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Status Code", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Status Code ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedProvince(value, update = false) {
            try {

                if (update == false) {
                    this.formInput.city = '';
                    this.formInput.district = '';
                    this.formInput.village = '';
                }

                if (value == null) {
                    this.cities = [];
                    this.districts = [];
                    this.villages = [];
                    this.formInput.city = '';
                    this.formInput.district = '';
                    this.formInput.village = '';
                    return;
                }

                this.formInput.province = value;
                const resp = await axios.post(
                    `dental-erp-service/area/cities`, {
                        state_id: value,
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.cities = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Cities"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Cities");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Cities", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Cities ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedCity(value, update = false) {
            try {

                if (update == false) {
                    this.formInput.district = '';
                    this.formInput.village = '';
                }

                if (value == null) {
                    this.formInput.city = '';
                    this.districts = [];
                    this.villages = [];
                    return;
                }

                this.formInput.city = value;
                const resp = await axios.post(
                    `dental-erp-service/area/districts`, {
                        city_id: value,
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.districts = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Districts"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Districts");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Districts", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Districts ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedDistrict(value, update = false) {
            try {

                if (update == false) {
                    this.formInput.village = '';
                }

                if (value == null) {
                    this.villages = [];
                    this.formInput.district = '';
                    return;
                }

                this.formInput.district = value;
                const resp = await axios.post(
                    `dental-erp-service/area/villages`, {
                        district_id: value,
                        country_id: 102
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.villages = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Villages"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Villages");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Villages", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Villages ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedVillage(value) {
            if (value == null) {
                this.formInput.village = '';
                return;
            }
            this.formInput.village = value;
        },
        async addPIC() {
            if (
                !this.pic.pic_npwp ||
                !this.pic.pic_name ||
                !this.pic.pic_title ||
                !this.pic.pic_phone_number ||
                !this.pic.pic_email ||
                !this.pic.pic_date_birth ||
                !this.pic.pic_address ||
                !this.pic.pic_notes
            ) {
                this.$swal.fire("Error", 'All fields can not empty', "error");
                return;
            }

            if (this.$route.query.id) {
                try {
                    let link = 'store';
                    if (this.pic.pic_id) {
                        link = 'update';
                    }

                    await this.$store.dispatch('setLoading', true);
                    const resp = await axios.post(
                        `dental-erp-service/customer_pic/${link}`, {
                            id: this.pic.pic_id,
                            npwp: this.pic.pic_npwp,
                            name: this.pic.pic_name,
                            title: this.pic.pic_title,
                            id_customer: this.$route.query.id,
                            address: this.pic.pic_address,
                            phone_number: this.pic.pic_phone_number,
                            email: this.pic.pic_email,
                            date_birth: this.pic.pic_date_birth,
                            notes: this.pic.pic_notes,
                        }, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        }
                    );

                    if (resp.data.error === false) {
                        this.$bvModal.hide('modal-add')
                        this.$swal.fire("Success", resp.data.message, "success");
                        this.refreshPIC();
                        return;
                    }

                    this.$swal.fire("Error", resp.data.message, "error");
                } catch (error) {
                    // if (typeof error.response.data != undefined) {
                    //     return this.$swal.fire("Error", error.response.data.message, "error");
                    //     // return await this.$toastr.e(
                    //     //     error.response.data.message,
                    //     //     "Request Add PIC"
                    //     // );
                    // }
                    // return this.$swal.fire("Error", error, "error");
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Request Add PIC", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                                return;
                            }
                            return this.$swal.fire("Request Add PIC ", error.response.data.message, "error");
                        }
                    }

                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                } finally {
                    await this.$store.dispatch('setLoading', false);
                }
            } else {

                this.formInput.pic.push(this.pic);
                this.pic = {
                    pic_npwp: '',
                    pic_name: '',
                    pic_title: '',
                    pic_email: '',
                    pic_phone_number: '',
                    pic_date_birth: '',
                    pic_address: '',
                    pic_notes: '',
                };
            }
        },
        async deletePIC(event) {
            await this.formInput.pic.splice(this.formInput.pic.indexOf(event), 1);
        },
        async destoryPIC(pic) {
            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch('setLoading', true);
                        const resp = await axios.delete(
                            `dental-erp-service/customer_pic/destroy`, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                                data: {
                                    id: pic.id
                                }
                            }
                        );

                        if (resp.data.error === false) {
                            this.refreshPIC();
                            // await this.formInput.pic.splice(this.formInput.pic.indexOf(index), 1);
                        }
                    } catch (error) {
                        // if (typeof error.response.data != undefined) {
                        //     return await this.$toastr.e(
                        //         error.response.data.message,
                        //         "Request Delete PIC"
                        //     );
                        // }
                        // return await this.$toastr.e(error, "Request Delete PIC");
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Request Delete PIC", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request Delete PIC ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch('setLoading', false);
                    }
                }
            });
        },
        async editPIC(pic) {
            this.pic = {
                pic_npwp: pic.npwp,
                pic_name: pic.name,
                pic_title: pic.title,
                pic_email: pic.email,
                pic_phone_number: pic.phone_number,
                pic_date_birth: pic.date_birth,
                pic_address: pic.address,
                pic_notes: pic.notes,
                pic_id: pic.id
            };
            this.$bvModal.show('modal-add');
        },
        async refreshPIC() {
            try {
                const resp = await axios.post(
                    `dental-erp-service/customer_pic/find`, {
                        id_customer: this.$route.query.id
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.formInput.pic = resp.data.data
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request PIC"
                //     );
                // }
                // return await this.$toastr.e(error, "Request PIC");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request PIC", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request PIC ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        onComplete() {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You want save this data!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.handleSubmit();
                }
            });
        },
        clearPIC() {
            this.pic = {
                pic_npwp: '',
                pic_name: '',
                pic_title: '',
                pic_email: '',
                pic_phone_number: '',
                pic_date_birth: '',
                pic_address: '',
                pic_notes: '',
            };
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.addPIC();
        },
        async handleSubmit() {
            this.$validate().then(function (success) {
                if (!success) {
                    return false;
                }
            });

            let link = "store";
            if (this.$route.query.id) {
                link = "update";
            }

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    `dental-erp-service/customer/${link}`,
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.clearForm();
                    // this.$parent.getData();
                    this.$router.push('CustomerAdmin');
                }
            } catch (error) {
                // console.log(error);
                // this.$swal.fire("Error", error.response.data.message, "error");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
        clearForm() {
            var self = this; //you need this because *this* will refer to Object.keys below`
            //Iterate through each object field, key is name of the object field`
            Object.keys(this.formInput).forEach(function (key) {
                self.formInput[key] = '';
            });
            this.formInput.pic = [];
            this.formInput.country = 102;
        }
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
