<template>
<div>
    <b-modal id="modal-edit" ref="my-modal-edit" title="Form Edit Principle" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk" @hidden="$parent.handleCancel">
        <form @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label class="text text-info">Part Number</label>
                    <input type="text" class="form-control" placeholder="Enter the part number" required v-model="formInput.part_number" />
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">Name</label>
                    <input type="text" class="form-control" placeholder="Enter the name" required v-model="formInput.name" />
                </div>
                <div class="form-group col-md-12">
                    <label class="text text-info">Description</label>
                    <input type="text" class="form-control" placeholder="Enter the description" required v-model="formInput.description" />
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Principle</label>
                    <v-select label="name" :filterable="false" :options="principle" v-model="formInput.principle_name" @search="onSearchPrinciple" @input="setSelectedPrinciple">
                        <template slot="no-options"> type to search principle... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.logo' style="width: 1rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Category</label>
                    <v-select label="name" :filterable="false" :options="category" v-model="formInput.category_name" @search="onSearchCategory" @input="setSelectedCategory">
                        <template slot="no-options"> type to search category... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-4 col-lg-4">
                    <label class="text text-info">Brand</label>
                    <v-select label="name" :options="brand" v-model="formInput.brand_name" @input="setSelectedBrand"></v-select>
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Unit</label>
                    <v-select v-model="formInput.piece" :options="pieces"></v-select>
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Safety Stock</label>
                    <input type="number" class="form-control" placeholder="Enter the safety stock" required v-model="formInput.safety_stock" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label class="text text-info mb-3">Images</label>
                    <b-form-file accept="image/*" v-on:change="onFileChange"></b-form-file>
                    <div class="row mt-5">
                        <div class="col-lg-2 col-md-4" v-for="(v,i) in images" :key="i">
                            <h6 v-if="v.file_json !== null">{{ JSON.parse(v.file_json).files.size }}</h6>
                            <div class="container-overlay">
                                <img :src="v.file_url" alt="" class="image-overlay">
                                <div @click="handleImageDelete(v.id)" class="overlay">
                                    <div class="text-overlay"> Delete </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Compressor from 'compressorjs';

export default {
    name: "EditProduct",
    props: {
        propsRow: {
            type: Object,
            required: true,
        },
        pieces: {
            type: Array,
        },
    },
    data() {
        return {
            files: [],
            principle: [],
            warehouse: [],
            brand: [],
            category: [],
            formInput: {},
            images: []
        };
    },
    watch: {
        propsRow: {
            immediate: true,
            handler() {
                this.formInput = Object.assign({}, this.propsRow);

                if (this.propsRow.id !== undefined) {
                    this.getDetailProduct()
                }
            },
        },
    },
    mounted() {
        this.getBrand();
    },
    methods: {
        async onFileChange(e) {
            const files = e.target.files[0]
            if (!files) return;
            const shelter = []

            // 180 x 144
            new Compressor(files, {
                quality: 0.8,
                width: 200,
                height: 200,
                success(result) {
                    const reader = new FileReader();
                    reader.readAsDataURL(result);

                    reader.onload = () => {
                        shelter.push({
                            content: reader.result,
                            size: '200x200',
                            name: result.name
                        });
                    };
                    // reader.readAsDataURL(result)
                }
            })

            // 360 x 270
            new Compressor(files, {
                quality: 0.8,
                width: 360,
                height: 270,
                success(result) {
                    const reader2 = new FileReader();
                    reader2.readAsDataURL(result);

                    reader2.onload = () => {
                        shelter.push({
                            content: reader2.result,
                            size: '360x270',
                            name: result.name
                        });
                    };
                    // reader2.readAsDataURL(result)
                }
            })

            this.files = shelter
        },

        async uploadFiles() {

            this.$store.dispatch("setLoading", true);

            axios.post("dental-erp-service/product/upload/image", {
                    id_ref: this.formInput.id,
                    file: this.files
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                })
                .then(resp => {
                    if (resp.data.error === false) {
                        return this.$swal.fire("Success", resp.data.message, "success");
                    }

                    this.$swal.fire("Error", resp.data.message, "error");
                })
                .catch(error => {
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Error", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                                return;
                            }
                            return this.$swal.fire("Request ", error.response.data.message, "error");
                        }
                    }

                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                })
                .then(() => {
                    this.$store.dispatch("setLoading", false);
                })
        },

        async handleImageDelete(id) {

            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    axios.delete("dental-erp-service/product/delete/image", {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                            data: {
                                id: id
                            }
                        })
                        .then(resp => {
                            if (resp.data.error === false) {
                                return this.$swal.fire("Success", resp.data.message, "success");
                            }

                            this.$swal.fire("Error", resp.data.message, "error");
                        })
                        .catch(error => {
                            if (error.response != undefined) {
                                if (error.response.data != undefined) {
                                    if (error.response.data.error_auth != undefined) {
                                        this.$swal.fire("Error", error.response.data.message, "error");
                                        this.$store.dispatch("logOut").then(() => {
                                            this.$router.push("/login");
                                        });
                                        return;
                                    }
                                    return this.$swal.fire("Request ", error.response.data.message, "error");
                                }
                            }

                            this.$sentry.captureException(error);
                            this.$swal.fire("Error", error.message, "error");
                        })
                        .then(() => {
                            this.$store.dispatch("setLoading", false);
                            this.getDetailProduct()
                        })
                }
            })
        },

        async getDetailProduct() {
            axios.post("dental-erp-service/product/find", {
                    id: this.formInput.id
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                })
                .then(res => {
                    this.images = res.data.data.images
                })
        },

        async onSearchPrinciple(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/principle", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.principle = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Principle"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Principle");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Principle", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Principle ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedPrinciple(selected) {
            if (selected == null) {
                this.formInput.id_principle = '';
                this.formInput.id_brand = '';
                this.formInput.brand_name = '';
                return;
            }
            this.formInput.id_principle = selected.id;
            this.getBrand();
        },
        async onSearchCategory(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/category", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.category = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Category"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Category");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Category", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Category ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedCategory(selected) {
            if (selected == null) {
                this.formInput.id_category = '';
                this.formInput.id_brand = '';
                this.formInput.brand_name = '';
                return;
            }
            this.formInput.id_category = selected.id;
            this.getBrand();
        },
        async setSelectedBrand(selected) {
            if (selected == null) {
                this.formInput.id_brand = '';
                this.formInput.brand_name = '';
                return;
            }
            this.formInput.id_brand = selected.id;
        },
        async getBrand() {
            this.formInput.id_brand = '';
            this.formInput.brand_name = '';

            if (this.formInput.id_principle.length != 0 && this.formInput.id_category.length != 0) {
                try {
                    const resp = await axios.get(
                        `dental-erp-service/brand/option?id_principle=${this.formInput.id_principle}&id_category=${this.formInput.id_category}`, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        }
                    );

                    if (resp.data.error === false) {
                        if (resp.data.data.length == 0) {
                            return await this.$toastr.w("Brand data does not exist", "Request Brand");
                        }
                        this.brand = resp.data.data;
                    }
                } catch (error) {
                    // if (typeof error.response.data != undefined) {
                    //     return await this.$toastr.e(
                    //         error.response.data.message,
                    //         "Request Brand"
                    //     );
                    // }
                    // return await this.$toastr.e(error, "Request Brand");
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Request Brand", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                                return;
                            }
                            return this.$swal.fire("Request Brand ", error.response.data.message, "error");
                        }
                    }

                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                }
            }
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                this.formInput.logo = [];
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/product/update",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide('modal-edit');
                    this.$swal.fire("Success", resp.data.message, "success");
                    if (this.files.length > 0) {
                        this.uploadFiles()
                    }
                    this.$parent.getData();
                }
            } catch (error) {
                // console.log(error);
                // this.$swal.fire("Error", error.response.data.message, "error");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
