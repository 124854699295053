<template>
<transition name="fade" mode="out-in" appear>
    <component :is="urlQuery"></component>
</transition>
</template>

<script>
// import axios from "axios";

import Dashboard from '../views/AdminModule/Dashboard';
import BrandAdmin from '../views/AdminModule/Brand/BrandAdmin';
import CategoryAdmin from '../views/AdminModule/Category/CategoryAdmin';
import CategoryPrincipleAdmin from '../views/AdminModule/CategoryPrinciple/CategoryPrincipleAdmin';
import CustomerAdmin from '../views/AdminModule/Customer/CustomerAdmin';
import PrincipleAdmin from '../views/AdminModule/Principle/PrincipleAdmin';
import ProductAdmin from '../views/AdminModule/Product/ProductAdmin';
import WarehouseAdmin from '../views/AdminModule/Warehouse/WarehouseAdmin';
import SupplierAdmin from '../views/AdminModule/Supplier/SupplierAdmin';
import BankAdmin from '../views/AdminModule/Bank/BankAdmin';
import PaymentAdmin from '../views/AdminModule/Payment/PaymentAdmin';
import AreaAdmin from '../views/AdminModule/Area/AreaAdmin';
import AddArea from '../views/AdminModule/Area/AddArea';
import DetailArea from '../views/AdminModule/Area/DetailArea';
import EditArea from '../views/AdminModule/Area/EditArea';
import EventAdmin from '../views/AdminModule/Event/EventAdmin';

// crud import
import FormCustomer from '../views/AdminModule/Customer/FormCustomer';
import FormPrinciple from '../views/AdminModule/Principle/FormPrinciple';
import FormSupplier from '../views/AdminModule/Supplier/FormSupplier';

export default {
    name: 'Warehouse',
    components: {
        Dashboard,
        BrandAdmin,
        CategoryAdmin,
        CategoryPrincipleAdmin,
        CustomerAdmin,
        PrincipleAdmin,
        SupplierAdmin,
        ProductAdmin,
        WarehouseAdmin,
        FormCustomer,
        FormPrinciple,
        FormSupplier,
        BankAdmin,
        PaymentAdmin,
        AreaAdmin,
        AddArea,
        DetailArea,
        EditArea,
        EventAdmin
    },
    // async created() {
    //     await this.$store.getters.isAdmin || this.$router.push('/');

    //     try {
    //         if (!this.$store.getters.isMenuEmpty) {
    //             return false;
    //         }

    //         const resp = await axios.get("user-service/menu", {
    //             headers: {
    //                 Authorization: localStorage.getItem("token") || "",
    //             },
    //         });

    //         if (resp.data.error === false) {
    //             this.listApplication = resp.data.data;
    //             await this.$store.dispatch('setMenu', resp.data.data);
    //             return;
    //         }
    //     } catch (error) {
    //         if (typeof error.response.data != undefined) {
    //             return await this.$toastr.e(error.response.data.message, "Get Application List");
    //         }
    //         return await this.$toastr.e(error, "Get Application List");
    //     }
    // },
    // data() {
    //     return {
    //         component: this.$route.params.component
    //     }
    // },
    computed: {
        urlQuery() {
            return this.$route.params.component || 'Dashboard';
        }
    }
}
</script>