<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>

        <b-button variant="outline-info" to="principle-admin">
            <b-icon icon="arrow-left" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Back to Principle List
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <form-wizard @on-complete="onComplete" :title="doUpdate ? 'Form Update Principle' : 'Form Add Principle' " subtitle="" color="#2f4cdd" stepSize="lg">
                        <tab-content title="Principle" icon="ti-notepad" :before-change="beforeTabSwitch">
                            <form @submit.prevent="handleSubmit">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label class="text text-info">NPWP</label>
                                        <input type="text" class="form-control" placeholder="Enter the npwp" required v-model="formInput.npwp" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.npwp') }}</label>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="text text-info">Name</label>
                                        <input type="text" class="form-control" placeholder="Enter the name" required v-model="formInput.name" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.name') }}</label>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="text text-info">Email</label>
                                        <input type="text" class="form-control" placeholder="Enter the email" required v-model="formInput.email" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.email') }}</label>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="text text-info">Phone Number</label>
                                        <input type="text" class="form-control" placeholder="Enter the phone number" required v-model="formInput.phone_number" @keypress="isNumber($event)" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.phone_number') }}</label>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="text text-info">Description</label>
                                        <input type="text" class="form-control" placeholder="Enter the description" required v-model="formInput.description" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.description') }}</label>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="text text-info">Address</label>
                                        <input type="text" class="form-control" placeholder="Enter the address" required v-model="formInput.address" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.address') }}</label>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="text text-info">Currency</label>
                                        <v-select :options="currency" v-model="formInput.currency"></v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.currency') }}</label>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="text text-info">Term of Payment</label>
                                        <v-select :options="term_of_payments" v-model="formInput.term_of_payment"></v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.term_of_payment') }}</label>
                                    </div>

                                </div>
                            </form>
                        </tab-content>
                        <tab-content title="PIC" icon="ti-user">
                            <div class="form-head d-flex mb-3 align-items-start">
                                <div class="mr-auto d-none d-lg-block"></div>
                                <b-button variant="outline-info" v-b-modal.modal-add-pic @click="clearPIC">
                                    <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Add PIC
                                </b-button>
                            </div>
                            <hr class="mt-3 mb-3">
                            <div class="table-responsive" v-show="formInput.pic.length > 0">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th><strong>NAME</strong></th>
                                            <th><strong>POSITION</strong></th>
                                            <th><strong>EMAIL</strong></th>
                                            <th><strong>PHONE NUMBER</strong></th>
                                            <th><strong>DATE OF BIRTH</strong></th>
                                            <th><strong>ADDRESS</strong></th>
                                            <th><strong>NOTES</strong></th>
                                            <th><strong>ACTION</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!doUpdate">
                                        <tr v-for="(pic, index) in formInput.pic" :key="index">
                                            <td><strong>{{ pic.name }}</strong></td>
                                            <td>{{ pic.title }}</td>
                                            <td>{{ pic.email }}</td>
                                            <td>{{ pic.phone_number }}</td>
                                            <td>{{ pic.date_birth }}</td>
                                            <td>{{ pic.address }}</td>
                                            <td>{{ pic.notes }}</td>
                                            <td>
                                                <div class="d-flex">
                                                    <button type="button" class="btn btn-danger shadow btn-xs sharp" @click="deletePIC(index)"><i class="fa fa-trash"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(pic, index) in formInput.pic" :key="index">
                                            <td><strong>{{ pic.name }}</strong></td>
                                            <td>{{ pic.title }}</td>
                                            <td>{{ pic.email }}</td>
                                            <td>{{ pic.phone_number }}</td>
                                            <td>{{ pic.date_birth }}</td>
                                            <td>{{ pic.address }}</td>
                                            <td>{{ pic.notes }}</td>
                                            <td>
                                                <div class="d-flex">
                                                    <button type="button" class="btn btn-info shadow btn-xs sharp mr-1" @click="editPIC(pic)"><i class="fa fa-pencil"></i></button>
                                                    <button type="button" class="btn btn-danger shadow btn-xs sharp" @click="destoryPIC(pic)"><i class="fa fa-trash"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </tab-content>
                        <tab-content title="Brand" icon="ti-user">
                            <div class="form-head d-flex mb-3 align-items-start">
                                <div class="mr-auto d-none d-lg-block"></div>
                                <b-button variant="outline-info" v-b-modal.modal-add-brand @click="clearBrand">
                                    <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Add Brand
                                </b-button>
                            </div>
                            <hr class="mt-3 mb-3">
                            <div class="table-responsive" v-show="formInput.brand.length > 0">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th><strong>NAME</strong></th>
                                            <th><strong>CATEGORY</strong></th>
                                            <th><strong>ACTION</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!doUpdateBrand">
                                        <tr v-for="(brand, index) in formInput.brand" :key="index">
                                            <td><strong>{{ brand.name }}</strong></td>
                                            <td>{{ brand.category_name }}</td>
                                            <td>
                                                <div class="d-flex">
                                                    <button type="button" class="btn btn-danger shadow btn-xs sharp" @click="deleteBrand(index)"><i class="fa fa-trash"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(brand, index) in formInput.brand" :key="index">
                                            <td><strong>{{ brand.name }}</strong></td>
                                            <td>{{ brand.category_name }}</td>
                                            <td>
                                                <div class="d-flex">
                                                    <button type="button" class="btn btn-info shadow btn-xs sharp mr-1" @click="editBrand(brand)"><i class="fa fa-pencil"></i></button>
                                                    <button type="button" class="btn btn-danger shadow btn-xs sharp" @click="destoryBrand(brand)"><i class="fa fa-trash"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </tab-content>
                    </form-wizard>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-add-pic" ref="myModalAddPic" title="Form Add PIC" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOkPIC">
        <form ref="formAdd" @submit.prevent="addPIC">
            <div class="form-row">
                <!-- <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">NPWP</label>
                    <input type="text" class="form-control" placeholder="Enter the PIC npwp" required v-model="pic.npwp" />
                </div> -->
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Name</label>
                    <input type="text" class="form-control" placeholder="Enter the PIC name" required v-model="pic.name" />
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Position</label>
                    <input type="text" class="form-control" placeholder="Enter the PIC position" required v-model="pic.title" />
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Email</label>
                    <input type="email" class="form-control" placeholder="Enter the PIC email" required v-model="pic.email" />
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Phone Number</label>
                    <input type="text" class="form-control" placeholder="Enter the PIC phone number" required v-model="pic.phone_number" @keypress="isNumber($event)" />
                </div>
                <div class="form-group col-md-4 col-lg-3">
                    <label class="text text-info">Date of Birth</label>
                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="pic.date_birth" initial-date="1990-01-01"></b-form-datepicker>
                </div>
                <div class="form-group col-md-6 col-lg-9">
                    <label class="text text-info">Address</label>
                    <input type="text" class="form-control" placeholder="Enter the PIC address" required v-model="pic.address" />
                </div>
                <div class="form-group col-md-6 col-lg-12">
                    <label class="text text-info">Notes</label>
                    <textarea class="form-control" rows="4" placeholder="Enter the notes" v-model="pic.notes"></textarea>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-add-brand" ref="myModalAddBrand" title="Form Add Brand" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOkBrand">
        <form ref="formAdd" @submit.prevent="addBrand">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label class="text text-info">Name</label>
                    <input type="text" class="form-control" placeholder="Enter the brand name" required v-model="brand.name" />
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">Category</label>
                    <v-select label="name" :filterable="false" :options="categories" v-model="brand.category_name" @input="setSelectedCategory" @search="onSearchCategory">
                        <template slot="no-options"> type to search categories... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.logo' style="width: 1rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import {
    Validator
} from 'simple-vue-validator';
import {
    FormWizard,
    TabContent
} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
    name: 'FormPrinciple',
    data() {
        return {
            categories: [],
            doUpdate: false,
            doUpdateBrand: false,
            currency: ['IDR', 'USD', 'EUR', 'JPY', 'CNY', 'KWD', 'BHD', 'OMR', 'JOD', 'GBP', 'KYD', 'CHF', 'CAD', 'AUD', 'LYD', 'AZN', 'MYR'],
            types: ['Principle', 'Supplier'],
            term_of_payments: ['Cash Before Delivery', 'Cash on Delivery', 'Cash in Advance', 'Net D Days on Payment Term', 'NET EOM days'],
            pic: {
                // npwp: '',
                name: '',
                title: '',
                email: '',
                phone_number: '',
                date_birth: '',
                address: '',
                notes: '',
            },
            brand: {
                name: '',
                id_category: '',
                category_name: ''
            },
            formInput: {
                npwp: "",
                name: "",
                description: "",
                phone_number: "",
                email: "",
                currency: "",
                type: "Principle",
                address: "",
                logo_content: "",
                logo_filename: "",
                term_of_payment: "",
                brand: [],
                pic: [],
            },
        }
    },
    components: {
        FormWizard,
        TabContent
    },
    validators: {
        'formInput.npwp': function (value) {
            return Validator.value(value).required('NPWP is required');
        },
        'formInput.name': function (value) {
            return Validator.value(value).required('Name is required');
        },
        'formInput.phone_number': function (value) {
            return Validator.value(value).required('Phone number is required').digit();
        },
        'formInput.email': function (value) {
            return Validator.value(value).required('Email is required').email();
        },
        'formInput.currency': function (value) {
            return Validator.value(value).required('Currency is required');
        },
        'formInput.address': function (value) {
            return Validator.value(value).required('Address is required');
        },
    },
    async mounted() {
        if (this.$route.query.id) {
            try {
                this.$store.dispatch('setLoading', true);
                this.doUpdate = true;
                this.doUpdateBrand = true;
                const resp = await axios.post(
                    'dental-erp-service/principle/find', {
                        id: this.$route.query.id
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.formInput = resp.data.data;
                    this.refreshBrand();
                    // this.formInput.pic = resp.data.data.pics;
                    // this.formInput.brand = resp.data.data.brands;
                    return;
                }

                await this.$toastr.e(
                    resp.data.message,
                    "Request Principle"
                );
                return this.$router.push('principle-admin');
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Principle"
                //     );
                //     return this.$router.push('PrincipleAdmin');
                // }
                // await this.$toastr.e(error, "Request Principle");
                // return this.$router.push('PrincipleAdmin');
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Principle", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Principle ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        }
    },
    methods: {
        async setSelectedCategory(val) {
            if (val == null) {
                this.brand.category_name = '';
                this.brand.id_category = '';
                return;
            }

            this.brand.category_name = val.name;
            this.brand.id_category = val.id;
        },
        async onSearchCategory(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/category", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.categories = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Category"
                //     );
                // }
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Category", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Category ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
                return await this.$toastr.e(error, "Request Category");
            }
        },
        async beforeTabSwitch() {
            let valid = await this.$validate();

            if (!valid) {
                return false;
            }
            return true;
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        clearPIC() {
            this.pic = {
                // npwp: '',
                name: '',
                title: '',
                email: '',
                phone_number: '',
                date_birth: '',
                address: '',
                notes: '',
            };
        },
        clearBrand() {
            this.brand = {
                name: '',
                id_category: '',
                category_name: ''
            };
        },
        onComplete() {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You want save this data!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.handleSubmit();
                }
            });
        },
        async handleOkPIC(bvModalEvt) {
            await bvModalEvt.preventDefault();
            let act = await this.addPIC();
            if (act) this.$bvModal.hide('modal-add-pic');
        },
        async handleOkBrand(bvModalEvt) {
            await bvModalEvt.preventDefault();
            let act = await this.addBrand();
            if (act) this.$bvModal.hide('modal-add-brand');
        },
        async addPIC() {
            if (
                // !this.pic.npwp ||
                !this.pic.name ||
                !this.pic.title ||
                !this.pic.phone_number ||
                !this.pic.email ||
                !this.pic.date_birth ||
                !this.pic.address ||
                !this.pic.notes
            ) {
                this.$swal.fire("Error", 'All fields can not empty', "error");
                return;
            }

            if (this.$route.query.id) {
                try {
                    let link = 'store';
                    if (this.pic.id) {
                        link = 'update';
                    }

                    await this.$store.dispatch('setLoading', true);
                    const resp = await axios.post(
                        `dental-erp-service/principle_pic/${link}`, {
                            id: this.pic.id,
                            // npwp: this.pic.npwp,
                            name: this.pic.name,
                            title: this.pic.title,
                            id_principle: this.$route.query.id,
                            address: this.pic.address,
                            phone_number: this.pic.phone_number,
                            email: this.pic.email,
                            date_birth: this.pic.date_birth,
                            notes: this.pic.notes,
                        }, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        }
                    );

                    if (resp.data.error === false) {
                        this.$swal.fire("Success", resp.data.message, "success");
                        this.refreshPIC();
                        return true;
                    }

                    this.$swal.fire("Error", resp.data.message, "error");
                } catch (error) {
                    // if (typeof error.response.data != undefined) {
                    //     return this.$swal.fire("Error", error.response.data.message, "error");
                    //     // return await this.$toastr.e(
                    //     //     error.response.data.message,
                    //     //     "Request Add PIC"
                    //     // );
                    // }
                    // return this.$swal.fire("Error", error, "error");
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Request Add PIC", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                                return;
                            }
                            return this.$swal.fire("Request Add PIC ", error.response.data.message, "error");
                        }
                    }

                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                } finally {
                    await this.$store.dispatch('setLoading', false);
                }
            } else {

                this.formInput.pic.push(this.pic);
                this.pic = {
                    // npwp: '',
                    name: '',
                    title: '',
                    email: '',
                    phone_number: '',
                    date_birth: '',
                    address: '',
                    notes: '',
                };
                return true;
            }
        },
        async destoryPIC(pic) {
            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch('setLoading', true);
                        const resp = await axios.delete(
                            `dental-erp-service/principle_pic/destroy`, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                                data: {
                                    id: pic.id
                                }
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.refreshPIC();
                            return;
                        }
                        this.$swal.fire("Error", resp.data.message, "error");
                    } catch (error) {
                        // if (typeof error.response.data != undefined) {
                        //     return await this.$toastr.e(
                        //         error.response.data.message,
                        //         "Request Delete PIC"
                        //     );
                        // }
                        // return await this.$toastr.e(error, "Request Delete PIC");
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Request Delete PIC", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request Delete PIC ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch('setLoading', false);
                    }
                }
            });
        },
        async destoryBrand(brand) {
            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch('setLoading', true);
                        const resp = await axios.delete(
                            `dental-erp-service/brand/destroy`, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                                data: {
                                    id: brand.id
                                }
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.refreshBrand();
                            return;
                        }
                        this.$swal.fire("Error", resp.data.message, "error");
                    } catch (error) {
                        // if (typeof error.response.data != undefined) {
                        //     return await this.$toastr.e(
                        //         error.response.data.message,
                        //         "Request Delete Brand"
                        //     );
                        // }
                        // return await this.$toastr.e(error, "Request Delete Brand");
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Request Delete Brand", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request Delete Brand ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch('setLoading', false);
                    }
                }
            });
        },
        async deletePIC(event) {
            await this.formInput.pic.splice(this.formInput.pic.indexOf(event), 1);
        },
        async deleteBrand(event) {
            await this.formInput.brand.splice(this.formInput.brand.indexOf(event), 1);
        },
        async editPIC(pic) {
            this.pic = {
                // npwp: pic.npwp,
                name: pic.name,
                title: pic.title,
                email: pic.email,
                phone_number: pic.phone_number,
                date_birth: pic.date_birth,
                address: pic.address,
                notes: pic.notes,
                id: pic.id
            };
            this.$bvModal.show('modal-add-pic');
        },
        async editBrand(brand) {
            this.brand = {
                id_category: brand.id_category,
                category_name: brand.category_name,
                name: brand.name,
                id: brand.id
            };
            this.$bvModal.show('modal-add-brand');
        },
        async addBrand() {
            if (
                !this.brand.name ||
                !this.brand.id_category
            ) {
                this.$swal.fire("Error", 'All fields can not empty', "error");
                return;
            }

            if (this.$route.query.id) {
                try {
                    let link = 'store';
                    if (this.brand.id) {
                        link = 'update';
                    }

                    await this.$store.dispatch('setLoading', true);
                    const resp = await axios.post(
                        `dental-erp-service/brand/${link}`, {
                            id: this.brand.id,
                            name: this.brand.name,
                            id_category: this.brand.id_category,
                            id_principle: this.$route.query.id,
                        }, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        }
                    );

                    if (resp.data.error === false) {
                        this.$swal.fire("Success", resp.data.message, "success");
                        this.refreshBrand();
                        return true;
                    }

                    this.$swal.fire("Error", resp.data.message, "error");
                } catch (error) {
                    // if (typeof error.response.data != undefined) {
                    //     return this.$swal.fire("Error", error.response.data.message, "error");
                    //     // return await this.$toastr.e(
                    //     //     error.response.data.message,
                    //     //     "Request Add PIC"
                    //     // );
                    // }
                    // return this.$swal.fire("Error", error, "error");
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Request Add PIC", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                                return;
                            }
                            return this.$swal.fire("Request Add PIC ", error.response.data.message, "error");
                        }
                    }

                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                } finally {
                    await this.$store.dispatch('setLoading', false);
                }
            } else {

                this.formInput.brand.push(this.brand);
                this.brand = {
                    name: '',
                    id_category: '',
                    category_name: ''
                };
                return true;
            }
        },
        async refreshPIC() {
            try {
                this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    `dental-erp-service/principle_pic/find`, {
                        id_principle: this.$route.query.id
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.formInput.pic = resp.data.data
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request PIC"
                //     );
                // }
                // return await this.$toastr.e(error, "Request PIC");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request PIC", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request PIC ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async refreshBrand() {
            try {
                this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    `dental-erp-service/brand`, {
                        ascending: false,
                        column: "id_principle",
                        limit: 500,
                        page: 1,
                        search: this.$route.query.id
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.formInput.brand = resp.data.data
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Brand"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Brand");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Brand", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Brand ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async handleSubmit() {
            let validation = await this.$validate();
            if (!validation) {
                return false;
            }

            let link = "store";
            if (this.$route.query.id) {
                link = "update";
            }

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    `dental-erp-service/principle/${link}`,
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.clearForm();
                    // this.$parent.getData();
                    this.$router.push('principle-admin');
                }
            } catch (error) {
                // console.log(error);
                // this.$swal.fire("Error", error.response.data.message, "error");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
        clearForm() {
            var self = this; //you need this because *this* will refer to Object.keys below`
            //Iterate through each object field, key is name of the object field`
            Object.keys(this.formInput).forEach(function (key) {
                self.formInput[key] = '';
            });
            this.formInput.type = 'Principle';
            this.formInput.pic = [];
            this.formInput.brand = [];
        }
    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
