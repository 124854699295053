<template>
<div>
    <b-modal id="modal-edit" ref="my-modal-edit" title="Form Edit Category Principle" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk" @hidden="$parent.handleCancel">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label class="text text-info">Name</label>
                    <input type="text" class="form-control" placeholder="Enter the name" required v-model="formInput.name" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label class="text text-info mb-3">Images</label>
                    <b-form-file accept="image/*" v-on:change="onFileChange"></b-form-file>
                    <div class="row mt-5">
                        <div class="col-lg-2 col-md-4" v-for="(v,i) in images" :key="i">
                            <h6 v-if="v.file_json !== null">{{ JSON.parse(v.file_json).files.size }}</h6>
                            <div class="container-overlay">
                                <img :src="v.file_url" alt="" class="image-overlay">
                                <div @click="handleImageDelete(v.id)" class="overlay">
                                    <div class="text-overlay"> Delete </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Compressor from 'compressorjs';

export default {
    name: "EditCategory",
    props: {
        propsRow: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            principle: [],
            formInput: {},
            files: [],
            images: []
        };
    },
    watch: {
        propsRow: {
            immediate: true,
            handler() {
                this.formInput = Object.assign({}, this.propsRow);

                if (this.propsRow.id !== undefined) {
                    this.getDetailCategory()
                }
            },
        },
    },
    methods: {

        async onFileChange(e) {
            const files = e.target.files[0]
            if (!files) return;
            const shelter = []

            // 280 x 175
            new Compressor(files, {
                quality: 0.8,
                width: 280,
                height: 175,
                success(result) {
                    const reader = new FileReader();
                    reader.readAsDataURL(result);

                    reader.onload = () => {
                        shelter.push({
                            content: reader.result,
                            size: '280x175',
                            name: result.name
                        });
                    };
                    // reader.readAsDataURL(result)
                }
            })

            // 200 x 200
            new Compressor(files, {
                quality: 0.8,
                width: 200,
                height: 200,
                success(result) {
                    const reader2 = new FileReader();
                    reader2.readAsDataURL(result);

                    reader2.onload = () => {
                        shelter.push({
                            content: reader2.result,
                            size: '200x200',
                            name: result.name
                        });
                    };
                    // reader2.readAsDataURL(result)
                }
            })

            this.files = shelter
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },

        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/category/update",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide('modal-edit');
                    this.$swal.fire("Success", resp.data.message, "success");
                    if (this.files.length > 0) {
                        this.uploadFiles()
                    }

                    this.$parent.getData();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },

        async uploadFiles() {

            this.$store.dispatch("setLoading", true);

            axios.post("dental-erp-service/category/upload/image", {
                    id_ref: this.formInput.id,
                    file: this.files
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                })
                .then(resp => {
                    if (resp.data.error === false) {
                        return this.$swal.fire("Success", resp.data.message, "success");
                    }

                    this.$swal.fire("Error", resp.data.message, "error");
                })
                .catch(error => {
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Error", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                                return;
                            }
                            return this.$swal.fire("Request ", error.response.data.message, "error");
                        }
                    }

                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                })
                .then(() => {
                    this.$store.dispatch("setLoading", false);
                })
        },

        async getDetailCategory() {
            axios.post("dental-erp-service/category/find", {
                    id: this.formInput.id
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                })
                .then(res => {
                    this.images = res.data.data.images
                })
        },

        async handleImageDelete(id) {

            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    axios.delete("dental-erp-service/category/delete/image", {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                            data: {
                                id: id
                            }
                        })
                        .then(resp => {
                            if (resp.data.error === false) {
                                return this.$swal.fire("Success", resp.data.message, "success");
                            }

                            this.$swal.fire("Error", resp.data.message, "error");
                        })
                        .catch(error => {
                            if (error.response != undefined) {
                                if (error.response.data != undefined) {
                                    if (error.response.data.error_auth != undefined) {
                                        this.$swal.fire("Error", error.response.data.message, "error");
                                        this.$store.dispatch("logOut").then(() => {
                                            this.$router.push("/login");
                                        });
                                        return;
                                    }
                                    return this.$swal.fire("Request ", error.response.data.message, "error");
                                }
                            }

                            this.$sentry.captureException(error);
                            this.$swal.fire("Error", error.message, "error");
                        })
                        .then(() => {
                            this.$store.dispatch("setLoading", false);
                            this.getDetailCategory()
                        })
                }
            })
        }
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

.container-overlay {
    position: relative;
}

.image-overlay {
    display: block;
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 10%;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
    cursor: pointer;
}

.container-overlay:hover .overlay {
    opacity: 1;
}

.text-overlay {
    color: white;
    font-size: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
</style>
