<template>
<div>
    <b-modal id="modal-form" ref="my-modal-form" :title="title" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk" button-size="sm" @hidden="$parent.handleCancel">
        <form @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6 col-lg-2">
                    <label class="text text-info">Code</label>
                    <input type="number" class="form-control" :disabled="formInput.id !== undefined" placeholder="Enter the code" required v-model.number="formInput.code" name="code" />
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Name</label>
                    <input type="text" class="form-control" :disabled="formInput.id !== undefined" placeholder="Enter the name" required v-model="formInput.name" name="name" />
                </div>
                <div class="form-group col-md-12 col-lg-6">
                    <label class="text text-info">Logo</label>
                    <b-form-file accept="image/*" v-model="files" v-on:change="onFileChange($event)"></b-form-file>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "FormBank",
    data() {
        return {
            title: 'Add Bank',
            formInput: {
                name: "",
                code: 1,
                filename: "",
                content: ""
            },
            files: null
        };
    },
    props: {
        propsRow: {
            type: Object,
        },
    },
    watch: {
        propsRow: {
            immediate: true,
            handler() {
                if (this.propsRow.id != undefined) {
                    this.title = 'Update Bank'
                    this.formInput = Object.assign({}, this.propsRow);
                } else {
                    this.title = 'Add Bank';
                    this.formInput = {
                        name: "",
                        code: 1,
                        filename: "",
                        content: ""
                    }
                }
                this.files = null
            },
        },
    },
    methods: {
        async onFileChange(event) {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                this.formInput.content = reader.result.split(',')[1];
                this.formInput.filename = event.target.files[0].name;
            };
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    this.formInput.id === undefined ? "dental-erp-service/bank/store" : "dental-erp-service/bank/update",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$bvModal.hide("modal-form");
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.files = null;
                    this.formInput = {
                        name: "",
                        code: 1,
                        filename: "",
                        content: ""
                    };
                    this.$parent.getData();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
