<template>
<div>
    <b-modal id="modal-add" ref="my-modal-add" title="Form Add Event" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label class="text text-info">Customer</label>
                    <v-select label="organization" :filterable="false" :options="customers" v-model="formInput.id_customer" :reduce="customer => customer.id" @search="onSearchCustomer">
                        <template slot="no-options"> type to search customer... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                {{ option.organization }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.logo' style="width: 1rem;height: auto;" />
                                {{ option.organization }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">Area</label>
                    <v-select label="name" :filterable="false" :options="areas" v-model="formInput.id_area" :reduce="area => area.id" @search="onSearchArea">
                        <template slot="no-options"> type to search area... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                <img :src='option.logo' style="width: 1rem;height: auto;" />
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">Title</label>
                    <input type="text" class="form-control" placeholder="Enter the Title" required v-model="formInput.title" />
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">Description</label>
                    <input type="text" class="form-control" placeholder="Enter the Description" required v-model="formInput.description" />
                </div>
                <div class="form-group col-md-4">
                    <label class="text text-info">Location</label>
                    <input type="text" class="form-control" placeholder="Enter the Location" required v-model="formInput.location" />
                </div>
                <div class="form-group col-md-4 col-lg-4">
                    <label class="text text-info">From</label>
                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric', 
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.start_date">
                    </b-form-datepicker>
                </div>
                <div class="form-group col-md-4 col-lg-4">
                    <label class="text text-info">To</label>
                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.end_date" :min="formInput.start_date">
                    </b-form-datepicker>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "AddEvent",
    data() {
        return {
            customers: [],
            areas: [],
            formInput: {
                name: "",
            },
        };
    },
    methods: {
        async onSearchCustomer(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/customer", {
                        limit: 20,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.customers = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Customer", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Customer ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async onSearchArea(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/area", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.areas = resp.data.data;
                }
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request Category"
                //     );
                // }
                // return await this.$toastr.e(error, "Request Category");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Area", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Area ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/events/store",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide("modal-add");
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.$refs.formAdd.reset();
                    this.$parent.getData();
                }
            } catch (error) {
                // console.log(error);
                // this.$swal.fire("Error", error.response.data.message, "error");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
